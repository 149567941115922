/* Default Template Styles */

/* Typography */
body {
  background: #fff;
}
p {
  color: #777;
  font-size: 0.85rem;
  line-height: 1.6rem;
}
a, a:link, a:visited, a:hover, a:active { 
color: #777;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000;                                         
  margin-bottom: 15px;
  margin-top: 0;
}
h1, .h1 {
  font-size: 1.8rem;
}
h2, .h2 {
  font-size: 1.4rem;
}
h3, .h3 {
  font-size: 1.2rem;
}
h4, .h4 {
  font-size: 1.1rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 0.9rem;
}
h1.headline, .h1.headline {
  font-size: 3.8rem; 
  letter-spacing: -2.5px;
}
h2.headline, .h2.headline {
  font-size: 3rem; 
  letter-spacing: -2px; 
}
h3.headline, .h3.headline {
  font-size: 2.6rem;
  letter-spacing: -2px;
}
h4.headline, .h4.headline {
  font-size: 2.4rem;
  letter-spacing: -1.5px; 
}
h5.headline, .h5.headline {
  font-size: 2.2rem;
  letter-spacing: -1.4px; 
}
h6.headline, .h6.headline {
  font-size: 2rem;
  letter-spacing: -1.3px;
}
.text-size-12, .text-l-size-12 {
  font-size: 12px !important;
  line-height: 1.4;
}
.text-size-16, .text-l-size-16 {
  font-size: 16px !important;
  line-height: 1.4;
}
.text-size-20, .text-l-size-20 {
  font-size: 20px !important;
  line-height: 1.4;
}
.text-size-25, .text-l-size-25 {
  font-size: 25px !important;
  line-height: 1.4;
}
.text-size-30, .text-l-size-30 {
  font-size: 30px !important;
  line-height: 1.4;
}
.text-size-40, .text-l-size-40 {
  font-size: 40px !important;
  line-height: 1.4;
}
.text-size-50, .text-l-size-50 {
  font-size: 50px !important;
  line-height: 1.4;
}
.text-size-60, .text-l-size-60 {
  font-size: 60px !important;
  line-height: 1.4;
}
.text-size-70, .text-l-size-70 {
  font-size: 70px !important;
  line-height: 1.4;
} 
.background-green .section-title:after, .primary-color-green .background-primary .section-title:after {        
  color: #fff;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-thin {
  font-weight: 300;
}
b, strong, .text-strong {
  font-weight: 700;
}
.text-extra-strong {
  font-weight: 800;
}
blockquote::before {
  color: #e0e0e0;
  content: "“";
  display: block;
  float: left;
  font-family: georgia;
  font-size: 80px;
  height: 30px;
  left: -40px;
  position: relative;
  top: -20px;
  width: 0;
}
blockquote {
  border: 0;
  font-size: 1rem;
  padding: 0 0 0 40px;
}
a.text-tag:link {
  border: 1px solid #e5e5e5;
  display: inline-block;
  float: left;
  font-size: 0.75rem;
  margin: 1px 2px 1px 0;
  padding: 6px 9px;
}
a.text-tag:link:hover {
  background: #002633 none repeat scroll 0 0;
  border: 1px solid #002633;
  color: #fff;
  transition: all 0.20s linear 0s;
  -o-transition: all 0.20s linear 0s;
  -ms-transition: all 0.20s linear 0s;
  -moz-transition: all 0.20s linear 0s;
  -webkit-transition: all 0.20s linear 0s;
}

ul.text-list, ol.text-list {
  font-size: 0.85rem;
  line-height: 1.8rem;
  padding: 0 16px;
}
ul.text-list ul, ol.text-list ol {
  padding: 0 14px;
}
iframe {
  display: block;
  margin: 0;
}

/* Drop Cap */
.text-drop-cap {
  float: left;
  font-size: 65px;
  line-height: 45px;
  padding-right: 10px;
  padding-top: 5px;
}

/* Tables */
table {
	background:none repeat scroll 0 0 #fff;
	border:0;
	font-size: 0.85rem;
  line-height: 1.6rem;
	}
table tr td, table tr th {padding:10px;}
table tfoot, table thead {
  background:none repeat scroll 0 0 #f5f5f5;
  border-top:1px solid #f0f0f0;
  border-bottom:1px solid #f0f0f0;
}
table tr:nth-of-type(2n) {
  background:none repeat scroll 0 0 #f5f5f5;
  border-top:1px solid #f0f0f0;
  border-bottom:1px solid #f0f0f0;
}
th {border-right:1px solid #fff;}
td {border-right:1px solid #fff;}


/* Backgrounds */
.background-white-hightlight .top-nav .active-item > a, .background-white-hightlight .top-nav li a:hover, .background-white-hightlight .aside-nav li a:hover, 
.background-white-hightlight .aside-nav > ul > li.active-item > a:link, .background-white-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-white .background-primary-hightlight .top-nav .active-item > a, .primary-color-white .background-primary-hightlight .top-nav li a:hover, .primary-color-white .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-white .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-white .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #fff none repeat scroll 0 0;
  color: #002633;
}
.background-yellow-hightlight .top-nav .active-item > a, .background-yellow-hightlight .top-nav li a:hover, .background-yellow-hightlight .aside-nav li a:hover, 
.background-yellow-hightlight .aside-nav > ul > li.active-item > a:link, .background-yellow-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-yellow .background-primary-hightlight .top-nav .active-item > a, .primary-color-yellow .background-primary-hightlight .top-nav li a:hover, .primary-color-yellow .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-yellow .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-yellow .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #F1D529 none repeat scroll 0 0;
  color: #002633;
}
.background-orange-hightlight .top-nav .active-item > a, .background-orange-hightlight .top-nav li a:hover, .background-orange-hightlight .aside-nav li a:hover, 
.background-orange-hightlight .aside-nav > ul > li.active-item > a:link, .background-orange-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-orange .background-primary-hightlight .top-nav .active-item > a, .primary-color-orange .background-primary-hightlight .top-nav li a:hover, .primary-color-orange .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-orange .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-orange .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #E4A600 none repeat scroll 0 0;
  color: #fff;
}
.background-red-hightlight .top-nav .active-item > a, .background-red-hightlight .top-nav li a:hover, .background-red-hightlight .aside-nav li a:hover, 
.background-red-hightlight .aside-nav > ul > li.active-item > a:link, .background-red-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-red .background-primary-hightlight .top-nav .active-item > a, .primary-color-red .background-primary-hightlight .top-nav li a:hover, .primary-color-red .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-red .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-red .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #C81010 none repeat scroll 0 0;
  color: #fff;
}
.background-pink-hightlight .top-nav .active-item > a, .background-pink-hightlight .top-nav li a:hover, .background-pink-hightlight .aside-nav li a:hover, 
.background-pink-hightlight .aside-nav > ul > li.active-item > a:link, .background-pink-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-pink .background-primary-hightlight .top-nav .active-item > a, .primary-color-pink .background-primary-hightlight .top-nav li a:hover, .primary-color-pink .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-pink .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-pink .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #DE1E5E none repeat scroll 0 0;
  color: #fff;
}
.background-blue-hightlight .top-nav .active-item > a, .background-blue-hightlight .top-nav li a:hover, .background-blue-hightlight .aside-nav li a:hover, 
.background-blue-hightlight .aside-nav > ul > li.active-item > a:link, .background-blue-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-blue .background-primary-hightlight .top-nav .active-item > a, .primary-color-blue .background-primary-hightlight .top-nav li a:hover, .primary-color-blue .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-blue .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-blue .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #005881 none repeat scroll 0 0;
  color: #fff;
} 
.background-aqua-hightlight .top-nav .active-item > a, .background-aqua-hightlight .top-nav li a:hover, .background-aqua-hightlight .aside-nav li a:hover, 
.background-aqua-hightlight .aside-nav > ul > li.active-item > a:link, .background-aqua-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-aqua .background-primary-hightlight .top-nav .active-item > a, .primary-color-aqua .background-primary-hightlight .top-nav li a:hover, .primary-color-aqua .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-aqua .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-aqua .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #00B5A6 none repeat scroll 0 0;
  color: #fff;
}
.background-green-hightlight .top-nav .active-item > a, .background-green-hightlight .top-nav li a:hover, .background-green-hightlight .aside-nav li a:hover, 
.background-green-hightlight .aside-nav > ul > li.active-item > a:link, .background-green-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-green .background-primary-hightlight .top-nav .active-item > a, .primary-color-green .background-primary-hightlight .top-nav li a:hover, .primary-color-green .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-green .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-green .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #007932 none repeat scroll 0 0;
  color: #fff;
}
.background-dark-hightlight .top-nav .active-item > a, .background-dark-hightlight .top-nav li a:hover, .background-dark-hightlight .aside-nav li a:hover, 
.background-dark-hightlight .aside-nav > ul > li.active-item > a:link, .background-dark-hightlight .aside-nav > ul > li.active-item > a:visited,
.primary-color-dark .background-primary-hightlight .top-nav .active-item > a, .primary-color-dark .background-primary-hightlight .top-nav li a:hover, .primary-color-dark .background-primary-hightlight .aside-nav li a:hover, 
.primary-color-dark .background-primary-hightlight .aside-nav > ul > li.active-item > a:link, .primary-color-dark .background-primary-hightlight .aside-nav > ul > li.active-item > a:visited {
  background: #002633 none repeat scroll 0 0;
  color: #fff;
}
.background-none {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0!important;
  border: 0;
}

/* Breaks */
hr.break {
  border: 0;
  border-top: 1px solid #e5e5e5; 
  display: block;
  margin: 40px 0;
}
hr.break:after {
clear:both;
content:".";
display:block;
height:0;
line-height:0;
visibility:hidden;
}
hr.break.break-dashed {
  border-top: 1px dashed #e5e5e5;
}
hr.break.break-dotted {
  border-top: 1px dotted #e5e5e5;
}
hr.break.break-double {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  height: 8px;
}
hr.break.break-dashed-double {
  border-bottom: 1px dashed #e5e5e5;
  border-top: 1px dashed #e5e5e5;
  height: 8px;
}
hr.break.break-dotted-double {
  border-bottom: 1px dotted #e5e5e5;
  border-top: 1px dotted #e5e5e5;
  height: 8px;
}

/* Small Breaks */
hr.break-small {
  background: #e5e5e5;
  border: 0;
  display: block;
  height: 2px;
  margin: 20px 0 35px;
  width: 60px;
}
hr.break-small.break-center {
  margin: 20px auto 35px;
}
hr.break-small.break-double {
  height: 0; 
  margin-bottom: 39px;
}
hr.break-small.break-double:before {
  background: #e5e5e5;
  border: 0 none;
  content: "";
  display: block;
  height: 2px;
  margin: 5px 0 0;
  width: 60px;
}
hr.break.break-small.break-double:after {
  background: #e5e5e5;
  border: 0 none;
  content: "";
  display: block;
  height: 2px;
  margin: 4px 0 35px;
  width: 60px;
}
hr.break-small.break-center.break-double:before {
  margin: 5px auto 0;
}
hr.break.break-small.break-center.break-double:after {
  margin: 4px auto 35px;
}
hr.break-small.break-double.background-white:before, hr.break-small.break-double.background-white:after, 
.primary-color-white hr.break-small.break-double.background-primary:before, .primary-color-white hr.break-small.break-double.background-primary:after {
  background: #fff;
}
hr.break-small.break-double.background-yellow:before, hr.break-small.break-double.background-yellow:after, 
.primary-color-yellow hr.break-small.break-double.background-primary:before, .primary-color-yellow hr.break-small.break-double.background-primary:after {
  background: #F1D529;
}
hr.break-small.break-double.background-orange:before, hr.break-small.break-double.background-orange:after,
.primary-color-orange hr.break-small.break-double.background-primary:before, .primary-color-orange hr.break-small.break-double.background-primary:after {
  background: #E4A600;
}
hr.break-small.break-double.background-red:before, hr.break-small.break-double.background-red:after,
.primary-color-red hr.break-small.break-double.background-primary:before, .primary-color-red hr.break-small.break-double.background-primary:after {
  background: #C81010;
}
hr.break-small.break-double.background-pink:before, hr.break-small.break-double.background-pink:after, 
.primary-color-pink hr.break-small.break-double.background-primary:before, .primary-color-pink hr.break-small.break-double.background-primary:after {
  background: #DE1E5E;
}
hr.break-small.break-double.background-blue:before, hr.break-small.break-double.background-blue:after,
.primary-color-blue hr.break-small.break-double.background-primary:before, .primary-color-blue hr.break-small.break-double.background-primary:after {
  background: #005881;
}
hr.break-small.break-double.background-aqua:before, hr.break-small.break-double.background-aqua:after, 
.primary-color-aqua hr.break-small.break-double.background-primary:before, .primary-color-aqua hr.break-small.break-double.background-primary:after {
  background: #00B5A6;
}
hr.break-small.break-double.background-green:before, hr.break-small.break-double.background-green:after,
.primary-color-green hr.break-small.break-double.background-primary:before, .primary-color-green hr.break-small.break-double.background-primary:after {
  background: #007932;
}

/* Animated carousel content */
.animated-carousel-element {
  position: relative;
  top: 200px;
  opacity: 0;
  animation-duration: 1s;
}

.active .animated-carousel-element {
  top: 0;
  opacity: 1;
  animation-name: bounceIn-carousel;
}

@keyframes bounceIn-carousel {
  0% {
    top: 200px;
    opacity: 0;
  }
  60% {
    top: -20px;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

/* Animated content */
.animated {
  position: relative;
  top: 200px;
  opacity: 0;
  animation-duration: 1s;
}

.slow {
  animation-duration: 2s;
}

.animation {
  top: 0;
  opacity: 1;
  animation-name: bounceIn;
}

@keyframes bounceIn {
  0% {
    top: 200px;
    opacity: 0;
  }
  60% {
    top: -10px;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}


video {
  display: block;
} 

/* Top Nav */
.top-nav li a, .background-white .top-nav li a {
  color: #002633;
  font-size: 0.85rem;
  padding: 0.7em 1.25em;
}
nav {
  border-bottom: 4px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1.7rem 0;
  position: relative;
  z-index: 2;
}  
.top-nav ul ul {
  background: #002633 none repeat scroll 0 0;
}
.top-nav li ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.top-nav li ul li:last-child {
  border-bottom: 0;
}
.top-nav li ul li a, .background-white .top-nav li ul li a, .top-nav .active-item li a {
  background: #002633 none repeat scroll 0 0;
  color: rgba(255,255,255, 0.75);
}
ul.chevron .submenu > a::after, ul.chevron .sub-submenu > a::after, ul.chevron .aside-submenu > a::after, ul.chevron .aside-sub-submenu > a::after {
  margin: 0 0 0 0.625rem;
}
.top-nav ul ul a {
  color: #eee;
}
.sticky {
  transition: all 0.20s linear 0s;
  -o-transition: all 0.20s linear 0s;
  -ms-transition: all 0.20s linear 0s;
  -moz-transition: all 0.20s linear 0s;
  -webkit-transition: all 0.20s linear 0s;
}
.fixed {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
nav.fixed, .fixed nav {
  padding: 1rem 0;
}
.logo img {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

/* Aside Nav */
aside {
  border-left: 1px solid #e5e5e5;
  padding-left: 1.25rem;
}

.aside-nav ul {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  margin-left: -1.25rem;
}
.aside-nav ul ul {
  margin: 0;
}
.aside-nav li a, .aside-nav li a:link, .aside-nav li a:visited {
  border-bottom: medium none;
  font-size: 0.85rem;
  padding: 15px;
  border-left: 5px solid rgba(0,0,0,0);
}
.aside-nav > ul > li > a:hover, .aside-nav > ul > li.active-item > a:link, .aside-nav > ul > li.active-item > a:visited {
  border-style: solid;
  border-width: 0 0 0 5px;
  border-color: #e5e5e5;
  background: none;
  color: #333;
}
.aside-nav li ul ul {
  background: #e9e9e9 none repeat scroll 0 0;
}
aside.aside-left {
  border-left: 0;
  border-right: 1px solid #e5e5e5;
  padding-right: 1.25rem;
}
.aside-left .aside-nav ul {
  margin-right: -1.25rem;
}
.aside-left .aside-nav li a {
  border-left: 0;
  border-right: 5px solid rgba(0,0,0,0);
}
.aside-left .aside-nav > ul > li > a:hover, .aside-left .aside-nav > ul > li.active-item > a:link, .aside-left .aside-nav > ul > li.active-item > a:visited {
  border-style: solid;
  border-width: 0 5px 0 0;
  border-color: #e5e5e5;
}

.aside-nav.background-white-hightlight > ul > li > a:hover, .aside-nav.background-white-hightlight > ul > li.active-item > a:link, .aside-nav.background-white-hightlight > ul > li.active-item > a:visited,
.primary-color-white .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-white .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-white .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #fff;
} 
.aside-nav.background-yellow-hightlight > ul > li > a:hover, .aside-nav.background-yellow-hightlight > ul > li.active-item > a:link, .aside-nav.background-yellow-hightlight > ul > li.active-item > a:visited,
.primary-color-yellow .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-yellow .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-yellow .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #F1D529;
} 
.aside-nav.background-orange-hightlight > ul > li > a:hover, .aside-nav.background-orange-hightlight > ul > li.active-item > a:link, .aside-nav.background-orange-hightlight > ul > li.active-item > a:visited,
.primary-color-orange .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-orange .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-orange .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #E4A600;
} 
.aside-nav.background-red-hightlight > ul > li > a:hover, .aside-nav.background-red-hightlight > ul > li.active-item > a:link, .aside-nav.background-red-hightlight > ul > li.active-item > a:visited,
.primary-color-red .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-red .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-red .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #C81010;
} 
.aside-nav.background-pink-hightlight > ul > li > a:hover, .aside-nav.background-pink-hightlight > ul > li.active-item > a:link, .aside-nav.background-pink-hightlight > ul > li.active-item > a:visited,
.primary-color-pink .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-pink .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-pink .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #DE1E5E;
} 
.aside-nav.background-blue-hightlight > ul > li > a:hover, .aside-nav.background-blue-hightlight > ul > li.active-item > a:link, .aside-nav.background-blue-hightlight > ul > li.active-item > a:visited,
.primary-color-blue .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-blue .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-blue .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #005881;
} 
.aside-nav.background-aqua-hightlight > ul > li > a:hover, .aside-nav.background-aqua-hightlight > ul > li.active-item > a:link, .aside-nav.background-aqua-hightlight > ul > li.active-item > a:visited,
.primary-color-aqua .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-aqua .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-aqua .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #00B5A6;
} 
.aside-nav.background-green-hightlight > ul > li > a:hover, .aside-nav.background-green-hightlight > ul > li.active-item > a:link, .aside-nav.background-green-hightlight > ul > li.active-item > a:visited,
.primary-color-green .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-green .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-green .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #007932;
}  
.aside-nav.background-dark-hightlight > ul > li > a:hover, .aside-nav.background-dark-hightlight > ul > li.active-item > a:link, .aside-nav.background-dark-hightlight > ul > li.active-item > a:visited,
.primary-color-dark .aside-nav.background-primary-hightlight > ul > li > a:hover, .primary-color-dark .aside-nav.background-primary-hightlight > ul > li.active-item > a:link, .primary-color-dark .aside-nav.background-primary-hightlight > ul > li.active-item > a:visited {
  border-color: #002633;
}

/* Font colors */
.background-white, .background-white p, a.background-white, .background-white a, .background-white a:link, .background-white a:visited, .background-white a:hover, .background-white a:active {
  color: #777;
} 
.background-dark, .background-dark p, a.background-dark, a.background-dark:visited, a.background-dark:link, .background-dark a, .background-dark a:link, .background-dark a:visited, .background-dark a:hover, .background-dark a:active,
.primary-color-dark .background-primary, .primary-color-dark .background-primary p, .primary-color-dark a.background-primary, .primary-color-dark a.background-primary:visited, .primary-color-dark a.background-primary:link, .primary-color-dark a.background-primary:visited, .primary-color-dark .background-primary a, .primary-color-dark .background-primary a:link, .primary-color-dark .background-primary a:visited, .primary-color-dark .background-primary a:hover, .primary-color-dark .background-primary a:active {
  color: #7697A2;
}
.background-white h1, .background-white h2, .background-white h3, .background-white h4, .background-white h5, .background-white h6,
.background-white .h1, .background-white .h2, .background-white .h3, .background-white .h4, .background-white .h5, .background-white .h6, 
.primary-color-white .background-primary h1, .primary-color-white .background-primary h2, .primary-color-white .background-primary h3, .primary-color-white .background-primary h4, .primary-color-white .background-primary h5, .primary-color-white .background-primary h6,
.primary-color-white .background-primary .h1, .primary-color-white .background-primary .h2, .primary-color-white .background-primary .h3, .primary-color-white .background-primary .h4, .primary-color-white .background-primary .h5, .primary-color-white .background-primary .h6,
.background-yellow h1, .background-yellow h2, .background-yellow h3, .background-yellow h4, .background-yellow h5, .background-yellow h6,
.background-yellow .h1, .background-yellow .h2, .background-yellow .h3, .background-yellow .h4, .background-yellow .h5, .background-yellow .h6,
.primary-color-yellow .background-primary h1, .primary-color-yellow .background-primary h2, .primary-color-yellow .background-primary h3, .primary-color-yellow .background-primary h4, .primary-color-yellow .background-primary h5, .primary-color-yellow .background-primary h6,
.primary-color-yellow .background-primary .h1, .primary-color-yellow .background-primary .h2, .primary-color-yellow .background-primary .h3, .primary-color-yellow .background-primary .h4, .primary-color-yellow .background-primary .h5, .primary-color-yellow .background-primary .h6,
.background-yellow, .background-yellow p, a.background-yellow, a.background-yellow:visited, a.background-yellow:link, .background-yellow a, .background-yellow a:link, .background-yellow a:visited, .background-yellow a:hover, .background-yellow a:active,
.primary-color-yellow .background-primary, .primary-color-yellow .background-primary p, .primary-color-yellow a.background-primary, .primary-color-yellow a.background-primary:visited, .primary-color-yellow a.background-primary:link, .primary-color-yellow .background-primary a, .primary-color-yellow .background-primary a:link, .primary-color-yellow .background-primary a:visited, .primary-color-yellow .background-primary a:hover, .primary-color-yellow .background-primary a:active {
  color: #002633;
} 
.image-hover-overlay-content *,
.background-orange, .background-orange p, a.background-orange, a.background-orange:visited, a.background-orange:link, .background-orange a, .background-orange a:link, .background-orange a:visited, .background-orange a:hover, .background-orange a:active,
.primary-color-orange .background-primary, .primary-color-orange .background-primary p, .primary-color-orange a.background-primary, .primary-color-orange a.background-primary:visited, .primary-color-orange a.background-primary:link, .primary-color-orange .background-primary a, .primary-color-orange .background-primary a:link, .primary-color-orange .background-primary a:visited, .primary-color-orange .background-primary a:hover, .primary-color-orange .background-primary a:active,
.background-red, .background-red p, a.background-red, a.background-red:visited, a.background-red:link, .background-red a, .background-red a:link, .background-red a:visited, .background-red a:hover, .background-red a:active,
.primary-color-red .background-primary, .primary-color-red .background-primary p, .primary-color-red a.background-primary, .primary-color-red a.background-primary:visited, .primary-color-red a.background-primary:link, .primary-color-red .background-primary a, .primary-color-red .background-primary a:link, .primary-color-red .background-primary a:visited, .primary-color-red .background-primary a:hover, .primary-color-red .background-primary a:active,
.background-pink, .background-pink p, a.background-pink, a.background-pink:visited, a.background-pink:link, .background-pink a, .background-pink a:link, .background-pink a:visited, .background-pink a:hover, .background-pink a:active,
.primary-color-pink .background-primary, .primary-color-pink .background-primary p, .primary-color-pink a.background-primary, .primary-color-pink a.background-primary:visited, .primary-color-pink a.background-primary:link, .primary-color-pink .background-primary a, .primary-color-pink .background-primary a:link, .primary-color-pink .background-primary a:visited, .primary-color-pink .background-primary a:hover, .primary-color-pink .background-primary a:active,
.background-blue, .background-blue p, a.background-blue, a.background-blue:visited, a.background-blue:link, .background-blue a, .background-blue a:link, .background-blue a:visited, .background-blue a:hover, .background-blue a:active,
.primary-color-blue .background-primary, .primary-color-blue .background-primary p, .primary-color-blue a.background-primary, .primary-color-blue a.background-primary:visited, .primary-color-blue a.background-primary:link, .primary-color-blue .background-primary a, .primary-color-blue .background-primary a:link, .primary-color-blue .background-primary a:visited, .primary-color-blue .background-primary a:hover, .primary-color-blue .background-primary a:active,
.background-aqua, .background-aqua p, a.background-aqua, a.background-aqua:visited, a.background-aqua:link, .background-aqua a, .background-aqua a:link, .background-aqua a:visited, .background-aqua a:hover, .background-aqua a:active, 
.primary-color-aqua .background-primary, .primary-color-aqua .background-primary p, .primary-color-aqua a.background-primary, .primary-color-aqua a.background-primary:visited, .primary-color-aqua a.background-primary:link, .primary-color-aqua .background-primary a, .primary-color-aqua .background-primary a:link, .primary-color-aqua .background-primary a:visited, .primary-color-aqua .background-primary a:hover, .primary-color-aqua .background-primary a:active,
.background-green, .background-green p, a.background-green, a.background-green:visited, a.background-green:link, .background-green a, .background-green a:link, .background-green a:visited, .background-green a:hover, .background-green a:active, 
.primary-color-green .background-primary, .primary-color-green .background-primary p, .primary-color-green a.background-primary, .primary-color-green a.background-primary:visited, .primary-color-green a.background-primary:link, .primary-color-green .background-primary a, .primary-color-green .background-primary a:link, .primary-color-green .background-primary a:visited, .primary-color-green .background-primary a:hover, .primary-color-green .background-primary a:active {
  color: rgba(255,255,255, 0.75);
}
.background-dark h1, .background-dark h2, .background-dark h3, .background-dark h4, .background-dark h5, .background-dark h6,
.background-dark .h1, .background-dark .h2, .background-dark .h3, .background-dark .h4, .background-dark .h5, .background-dark .h6, 
.primary-color-dark .background-primary h1, .primary-color-dark .background-primary h2, .primary-color-dark .background-primary h3, .primary-color-dark .background-primary h4, .primary-color-dark .background-primary h5, .primary-color-dark .background-primary h6,
.primary-color-dark .background-primary .h1, .primary-color-dark .background-primary .h2, .primary-color-dark .background-primary .h3, .primary-color-dark .background-primary .h4, .primary-color-dark .background-primary .h5, .primary-color-dark .background-primary .h6, 
.background-orange h1, .background-orange h2, .background-orange h3, .background-orange h4, .background-orange h5, .background-orange h6,
.background-orange .h1, .background-orange .h2, .background-orange .h3, .background-orange .h4, .background-orange .h5, .background-orange .h6,
.primary-color-orange .background-primary h1, .primary-color-orange .background-primary h2, .primary-color-orange .background-primary h3, .primary-color-orange .background-primary h4, .primary-color-orange .background-primary h5, .primary-color-orange .background-primary h6,
.primary-color-orange .background-primary .h1, .primary-color-orange .background-primary .h2, .primary-color-orange .background-primary .h3, .primary-color-orange .background-primary .h4, .primary-color-orange .background-primary .h5, .primary-color-orange .background-primary .h6, 
.background-red h1, .background-red h2, .background-red h3, .background-red h4, .background-red h5, .background-red h6,
.background-red .h1, .background-red .h2, .background-red .h3, .background-red .h4, .background-red .h5, .background-red .h6,
.primary-color-red .background-primary h1, .primary-color-red .background-primary h2, .primary-color-red .background-primary h3, .primary-color-red .background-primary h4, .primary-color-red .background-primary h5, .primary-color-red .background-primary h6,
.primary-color-red .background-primary .h1, .primary-color-red .background-primary .h2, .primary-color-red .background-primary .h3, .primary-color-red .background-primary .h4, .primary-color-red .background-primary .h5, .primary-color-red .background-primary .h6, 
.background-pink h1, .background-pink h2, .background-pink h3, .background-pink h4, .background-pink h5, .background-pink h6,
.background-pink .h1, .background-pink .h2, .background-pink .h3, .background-pink .h4, .background-pink .h5, .background-pink .h6,
.primary-color-pink .background-primary h1, .primary-color-pink .background-primary h2, .primary-color-pink .background-primary h3, .primary-color-pink .background-primary h4, .primary-color-pink .background-primary h5, .primary-color-pink .background-primary h6,
.primary-color-pink .background-primary .h1, .primary-color-pink .background-primary .h2, .primary-color-pink .background-primary .h3, .primary-color-pink .background-primary .h4, .primary-color-pink .background-primary .h5, .primary-color-pink .background-primary .h6, 
.background-blue h1, .background-blue h2, .background-blue h3, .background-blue h4, .background-blue h5, .background-blue h6,
.background-blue .h1, .background-blue .h2, .background-blue .h3, .background-blue .h4, .background-blue .h5, .background-blue .h6,
.primary-color-blue .background-primary h1, .primary-color-blue .background-primary h2, .primary-color-blue .background-primary h3, .primary-color-blue .background-primary h4, .primary-color-blue .background-primary h5, .primary-color-blue .background-primary h6,
.primary-color-blue .background-primary .h1, .primary-color-blue .background-primary .h2, .primary-color-blue .background-primary .h3, .primary-color-blue .background-primary .h4, .primary-color-blue .background-primary .h5, .primary-color-blue .background-primary .h6, 
.background-aqua h1, .background-aqua h2, .background-aqua h3, .background-aqua h4, .background-aqua h5, .background-aqua h6,
.background-aqua .h1, .background-aqua .h2, .background-aqua .h3, .background-aqua .h4, .background-aqua .h5, .background-aqua .h6,
.primary-color-aqua .background-primary h1, .primary-color-aqua .background-primary h2, .primary-color-aqua .background-primary h3, .primary-color-aqua .background-primary h4, .primary-color-aqua .background-primary h5, .primary-color-aqua .background-primary h6,
.primary-color-aqua .background-primary .h1, .primary-color-aqua .background-primary .h2, .primary-color-aqua .background-primary .h3, .primary-color-aqua .background-primary .h4, .primary-color-aqua .background-primary .h5, .primary-color-aqua .background-primary .h6, 
.background-green h1, .background-green h2, .background-green h3, .background-green h4, .background-green h5, .background-green h6,
.background-green .h1, .background-green .h2, .background-green .h3, .background-green .h4, .background-green .h5, .background-green .h6, 
.primary-color-green .background-primary h1, .primary-color-green .background-primary h2, .primary-color-green .background-primary h3, .primary-color-green .background-primary h4, .primary-color-green .background-primary h5, .primary-color-green .background-primary h6,
.primary-color-green .background-primary .h1, .primary-color-green .background-primary .h2, .primary-color-green .background-primary .h3, .primary-color-green .background-primary .h4, .primary-color-green .background-primary .h5, .primary-color-green .background-primary .h6 {
  color: #fff;
}
.text-white, .text-white *, .primary-color-white .text-primary, .primary-color-white .text-primary * {
  color: #fff !important;
}
.text-yellow, .text-yellow *, .primary-color-yellow .text-primary, .primary-color-yellow .text-primary * {
  color: #F1D529 !important;
}
.text-orange, .text-orange *, .primary-color-orange .text-primary, .primary-color-orange .text-primary * {
  color: #E4A600 !important;
}
.text-red, .text-red *, .primary-color-red .text-primary, .primary-color-red .text-primary * {
  color: #C81010 !important;
}
.text-pink, .text-pink *, .primary-color-pink .text-primary, .primary-color-pink .text-primary * {
  color: #DE1E5E !important;
}
.text-blue, .text-blue *, .primary-color-blue .text-primary, .primary-color-blue .text-primary * {
  color: #005881 !important;
}
.text-aqua, .text-aqua *, .primary-color-aqua .text-primary, .primary-color-aqua .text-primary * {
  color: #00B5A6 !important;
}
.text-green, .text-green *, .primary-color-green .text-primary, .primary-color-green .text-primary * {
  color: #007932 !important;
}
.text-dark, .text-dark *, .primary-color-dark .text-primary, .primary-color-dark .text-primary * {
  color: #002633 !important;
} 
.text-primary-hover, .text-white-hover, .text-yellow-hover, .text-orange-hover, .text-red-hover, .text-pink-hover, .text-blue-hover, .text-aqua-hover, .text-green-hover, .text-dark-hover {
  transition: color 0.20s linear 0s;
  -o-transition: color 0.20s linear 0s;
  -ms-transition: color 0.20s linear 0s;
  -moz-transition: color 0.20s linear 0s;
  -webkit-transition: color 0.20s linear 0s;
}
.text-white-hover:hover, .primary-color-white .text-primary-hover:hover {
  color: #fff !important;
}
.text-yellow-hover:hover, .primary-color-yellow .text-primary-hover:hover {
  color: #F1D529 !important;
}
.text-orange-hover:hover, .primary-color-orange .text-primary-hover:hover {
  color: #E4A600 !important;
}
.text-red-hover:hover, .primary-color-red .text-primary-hover:hover {
  color: #C81010 !important;
}
.text-pink-hover:hover, .primary-color-pink .text-primary-hover:hover {
  color: #DE1E5E !important;
}
.text-blue-hover:hover, .primary-color-blue .text-primary-hover:hover {
  color: #005881 !important;
}
.text-aqua-hover:hover, .primary-color-aqua .text-primary-hover:hover {
  color: #00B5A6 !important;
}
.text-green-hover:hover, .primary-color-green .text-primary-hover:hover {
  color: #007932 !important;
}
.text-dark-hover:hover, .primary-color-dark .text-primary-hover:hover {
  color: #002633 !important;
} 

/* Background Colors */
.background-white, .primary-color-white .background-primary {
  background-color: #fff !important;
}
.background-yellow, .primary-color-yellow .background-primary {
  background-color: #F1D529 !important;
}
.background-orange, .primary-color-orange .background-primary {
  background-color: #E4A600 !important;
}
.background-red, .primary-color-red .background-primary {
  background-color: #C81010 !important;
}
.background-pink, .primary-color-pink .background-primary {
  background-color: #DE1E5E !important;
}
.background-blue, .primary-color-blue .background-primary {
  background-color: #005881 !important;
}
.background-aqua, .primary-color-aqua .background-primary {
  background-color: #00B5A6 !important;
}
.background-green, .primary-color-green .background-primary {
  background-color: #007932 !important;
}
.background-dark, .primary-color-dark .background-primary {
  background-color: #002633 !important; 
}
/* Background Opacity */
.background-white.background-transparent, .primary-color-white .background-primary.background-transparent {
  background-color: rgba(255, 255, 255, 0.85) !important;
}
.background-yellow.background-transparent, .primary-color-yellow .background-primary.background-transparent {
  background-color: rgba(241, 213, 41, 0.85) !important;
}
.background-orange.background-transparent, .primary-color-orange .background-primary.background-transparent {
  background-color: rgba(228, 166, 0, 0.85) !important;
}
.background-red.background-transparent, .primary-color-red .background-primary.background-transparent {
  background-color: rgba(200, 16, 16, 0.85) !important;
}
.background-pink.background-transparent, .primary-color-pink .background-primary.background-transparent {
  background-color: rgba(222, 30, 94, 0.85) !important;
}
.background-blue.background-transparent, .primary-color-blue .background-primary.background-transparent {
  background-color: rgba(0, 88, 129, 0.85) !important;
}
.background-aqua.background-transparent, .primary-color-aqua .background-primary.background-transparent {
  background-color: rgba(0, 181, 166, 0.85) !important;
}
.background-green.background-transparent, .primary-color-green .background-primary.background-transparent {
  background-color: rgba(0, 121, 50, 0.85) !important;
}
.background-dark.background-transparent, .primary-color-dark .background-primary.background-transparent {
  background-color: rgba(0, 38, 51, 0.85) !important;
}


/* Hover Overlay */
.image-hover-overlay {
  bottom: 0;
  color: rgba(255,255,255, 0.75)!important;
  left: 0;
  opacity: 0;
  padding: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  transition: all 0.20s linear 0s;
  -o-transition: all 0.20s linear 0s;
  -ms-transition: all 0.20s linear 0s;
  -moz-transition: all 0.20s linear 0s;
  -webkit-transition: all 0.20s linear 0s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.image-border-radius .image-hover-overlay {
  border-radius: 3px;
}
.image-hover-overlay:hover {
  opacity: 1;
}
.image-hover-overlay-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  transition: all 0.20s linear 0s;
  -o-transition: all 0.20s linear 0s;
  -ms-transition: all 0.20s linear 0s;
  -moz-transition: all 0.20s linear 0s;
  -webkit-transition: all 0.20s linear 0s;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.image-hover-overlay:hover > .image-hover-overlay-content {
  top: 50%;
}

/* Image blocks */
.image-with-hover-overlay {
  display: block;
  position: relative;
}
.image-border-radius img {
  border-radius: 3px;
}
.image-hover-zoom {
  display: block;
  overflow: hidden;
}
.image-hover-zoom img {
  transition: all 0.20s linear 0s;
  -o-transition: all 0.20s linear 0s;
  -ms-transition: all 0.20s linear 0s;
  -moz-transition: all 0.20s linear 0s;
  -webkit-transition: all 0.20s linear 0s;
}
.image-with-hover-overlay img {
  width: 100%;
}
.image-hover-zoom:hover img {
  transform: scale(1.1);
}


/* Buttons */
.button, a.button, a.button:link, a.button:visited {
  border-color: rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.3);
  border-style: solid;
  border-width: 1px;
  color: white;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  text-align: center;
  transition: all 0.20s linear 0s !important;
  -o-transition: all 0.20s linear 0s !important;
  -ms-transition: all 0.20s linear 0s !important;
  -moz-transition: all 0.20s linear 0s !important;
  -webkit-transition: all 0.20s linear 0s !important;
}

.button:hover, a.button:hover, a.button:link:hover, a.button:visited:hover {
  box-shadow: 0 0 100px 100px rgba(255, 255, 255, 0.25) inset;
}
/* Stroke Buttons */ 
.button.button-white-stroke, a.button.button-white-stroke, a.button.button-white-stroke:link, a.button.button-white-stroke:visited,
.primary-color-white .button.button-primary-stroke, .primary-color-white a.button.button-primary-stroke, .primary-color-white a.button.button-primary-stroke:link, .primary-color-white a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #fff;
  box-shadow: none;
}
.button.button-white-stroke:hover, a.button.button-white-stroke:hover,
.primary-color-white .button.button-primary-stroke:hover, .primary-color-white a.button.button-primary-stroke:hover {
  background: #fff none repeat scroll 0 0;
  color: #002633;
}
.button.button-yellow-stroke, a.button.button-yellow-stroke, a.button.button-yellow-stroke:link, a.button.button-yellow-stroke:visited,
.primary-color-yellow .button.button-primary-stroke, .primary-color-yellow a.button.button-primary-stroke, .primary-color-yellow a.button.button-primary-stroke:link, .primary-color-yellow a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #F1D529;
  box-shadow: none;
  color: #F1D529;
}
.button.button-yellow-stroke:hover, a.button.button-yellow-stroke:hover,
.primary-color-yellow .button.button-primary-stroke:hover, .primary-color-yellow a.button.button-primary-stroke:hover {
  background: #F1D529 none repeat scroll 0 0;
  color: #002633;
}
.button.button-orange-stroke, a.button.button-orange-stroke, a.button.button-orange-stroke:link, a.button.button-orange-stroke:visited,
.primary-color-orange .button.button-primary-stroke, .primary-color-orange a.button.button-primary-stroke, .primary-color-orange a.button.button-primary-stroke:link, .primary-color-orange a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #E4A600;
  box-shadow: none;
  color: #E4A600;
}
.button.button-orange-stroke:hover, a.button.button-orange-stroke:hover,
.primary-color-orange .button.button-primary-stroke:hover, .primary-color-orange a.button.button-primary-stroke:hover {
  background: #E4A600 none repeat scroll 0 0;
  color: #fff;
}
.button.button-red-stroke, a.button.button-red-stroke, a.button.button-red-stroke:link, a.button.button-red-stroke:visited,
.primary-color-red .button.button-primary-stroke, .primary-color-red a.button.button-primary-stroke, .primary-color-red a.button.button-primary-stroke:link, .primary-color-red a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #C81010;
  box-shadow: none;
  color: #C81010;
}
.button.button-red-stroke:hover, a.button.button-red-stroke:hover,
.primary-color-red .button.button-primary-stroke:hover, .primary-color-red a.button.button-primary-stroke:hover {
  background: #C81010 none repeat scroll 0 0;
  color: #fff;
}
.button.button-pink-stroke, a.button.button-pink-stroke, a.button.button-pink-stroke:link, a.button.button-pink-stroke:visited,
.primary-color-pink .button.button-primary-stroke, .primary-color-pink a.button.button-primary-stroke, .primary-color-pink a.button.button-primary-stroke:link, .primary-color-pink a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #DE1E5E;
  box-shadow: none;
  color: #DE1E5E;
}
.button.button-pink-stroke:hover, a.button.button-pink-stroke:hover,
.primary-color-pink .button.button-primary-stroke:hover, .primary-color-pink a.button.button-primary-stroke:hover {
  background: #DE1E5E none repeat scroll 0 0;
  color: #fff;
}
.button.button-blue-stroke, a.button.button-blue-stroke, a.button.button-blue-stroke:link, a.button.button-blue-stroke:visited,
.primary-color-blue .button.button-primary-stroke, .primary-color-blue a.button.button-primary-stroke, .primary-color-blue a.button.button-primary-stroke:link, .primary-color-blue a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #005881;
  box-shadow: none;
  color: #005881;
}
.button.button-blue-stroke:hover, a.button.button-blue-stroke:hover,
.primary-color-blue .button.button-primary-stroke:hover, .primary-color-blue a.button.button-primary-stroke:hover {
  background: #005881 none repeat scroll 0 0;
  color: #fff;
}
.button.button-aqua-stroke, a.button.button-aqua-stroke, a.button.button-aqua-stroke:link, a.button.button-aqua-stroke:visited,
.primary-color-aqua .button.button-primary-stroke, .primary-color-aqua a.button.button-primary-stroke, .primary-color-aqua a.button.button-primary-stroke:link, .primary-color-aqua a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #00B5A6;
  box-shadow: none;
  color: #00B5A6;
}
.button.button-aqua-stroke:hover, a.button.button-aqua-stroke:hover,
.primary-color-aqua .button.button-primary-stroke:hover, .primary-color-aqua a.button.button-primary-stroke:hover {
  background: #00B5A6 none repeat scroll 0 0;
  color: #fff;
}
.button.button-green-stroke, a.button.button-green-stroke, a.button.button-green-stroke:link, a.button.button-green-stroke:visited,
.primary-color-green .button.button-primary-stroke, .primary-color-green a.button.button-primary-stroke, .primary-color-green a.button.button-primary-stroke:link, .primary-color-green a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #007932;
  box-shadow: none;
  color: #007932;
}
.button.button-green-stroke:hover, a.button.button-green-stroke:hover,
.primary-color-green .button.button-primary-stroke:hover, .primary-color-green a.button.button-primary-stroke:hover {
  background: #007932 none repeat scroll 0 0;
  color: #fff;
}
.button.button-dark-stroke, a.button.button-dark-stroke, a.button.button-dark-stroke:link, a.button.button-dark-stroke:visited,
.primary-color-dark .button.button-primary-stroke, .primary-color-dark a.button.button-primary-stroke, .primary-color-dark a.button.button-primary-stroke:link, .primary-color-dark a.button.button-primary-stroke:visited {
  background: none;
  border: 1px solid #002633;
  box-shadow: none;
  color: #002633;
}
.button.button-dark-stroke:hover, a.button.button-dark-stroke:hover,
.primary-color-dark .button.button-primary-stroke:hover, .primary-color-dark a.button.button-primary-stroke:hover {
  background: #002633 none repeat scroll 0 0;
  color: #fff;
}

/* Containers */
.section  { 
  padding: 6rem 1.25rem;
}
.section-small-padding  { 
  padding: 2.5rem 1.25rem;
}
.section-top-padding  { 
  padding-top: 6rem;
}
.section-top-small-padding  { 
  padding-top: 2.5rem;
}
.float-left {
  float: left;
}
.block-bordered {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.padding {
  padding: 1.25rem!important;
}
.padding-2x {
  padding: 2.5rem!important;
}
.full-width:after {
clear:both;
content:".";
display:block;
height:0;
line-height:0;
visibility:hidden;
}
.position-fixed  { 
  position: fixed;
  top: 0;
  width: 100%;
}
.center {
  display: block!important;
}
.border-radius  { 
  border-radius: 3px;
}
.content-center-vertical {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%; 
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform:translateY(-50%);
}
.content-bottom {
  bottom: 0;
  position: absolute;
}
.grayscale {
  filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);    
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
}

.image-grayscale {
  filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);    
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
  position: relative;
}
*:hover > .image-grayscale {
  filter: none;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);    
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=0);
}


/* Margins */
.margin-top {
  margin-top: 1.25rem !important;
}
.margin-left {
  margin-left: 1.25rem !important;
}
.margin-right {
  margin-right: 1.25rem !important;
}
.margin-top-bottom {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.margin-left-right {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.margin-top-0 {
  margin-top: 0 !important;
  display: block;
}
.margin-top-10 {
  margin-top: 10px !important;
  display: block;
}
.margin-top-15 {
  margin-top: 15px !important;
  display: block;
} 
.margin-top-20 {
  margin-top: 20px !important;
  display: block;
} 
.margin-top-30 {
  margin-top: 30px !important;
  display: block;
} 
.margin-top-40 {
  margin-top: 40px !important;
  display: block;
}
.margin-top-50 {
  margin-top: 50px !important;
  display: block;
}
.margin-top-60 {
  margin-top: 60px !important;
  display: block;
}
.margin-top-70 {
  margin-top: 70px !important;
  display: block;
}
.margin-top-80 {
  margin-top: 80px !important;
  display: block;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
  display: block;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
  display: block;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
  display: block;
} 
.margin-bottom-20 {
  margin-bottom: 20px !important;
  display: block;
} 
.margin-bottom-30 {
  margin-bottom: 30px !important;
  display: block;
} 
.margin-bottom-40 {
  margin-bottom: 40px !important;
  display: block;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
  display: block;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
  display: block;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
  display: block;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
  display: block;
}
.margin-top-bottom-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: block;
}
.margin-top-bottom-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: block;
}
.margin-top-bottom-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  display: block;
} 
.margin-top-bottom-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  display: block;
} 
.margin-top-bottom-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  display: block;
} 
.margin-top-bottom-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  display: block;
}
.margin-top-bottom-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  display: block;
}
.margin-top-bottom-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
  display: block;
} 
.margin-top-bottom-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
  display: block;
} 
.margin-top-bottom-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
  display: block;
}

.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
} 
.margin-left-20 {
  margin-left: 20px !important;
} 
.margin-left-30 {
  margin-left: 30px !important;
} 
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
} 
.margin-right-20 {
  margin-right: 20px !important;
} 
.margin-right-30 {
  margin-right: 30px !important;
} 
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
.margin-left-right-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.margin-left-right-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.margin-left-right-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
} 
.margin-left-right-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
} 
.margin-left-right-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
} 
.margin-left-right-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.margin-left-right-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.margin-left-right-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
} 
.margin-left-right-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
} 
.margin-left-right-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}        

/* More info button */
a.text-more-info {
  display: block;
  font-size: 0.85rem;
  margin-top: 0.625rem;
}
a.text-more-info:after {
  content: "\f006";
  font-family: mfg;
  font-size: 0.8rem;
  margin-left: 0.625rem;
  transition: all 0.20s linear 0s;
-o-transition: all 0.20s linear 0s;
-ms-transition: all 0.20s linear 0s;
-moz-transition: all 0.20s linear 0s;
-webkit-transition: all 0.20s linear 0s;
}
a.text-more-info:hover:after {
  margin-left: 0.825rem;
}

/* Top bar */
.top-bar-contact p {
  height: 35px;
  line-height: 35px;
}
.top-bar-social li {
	border-left: 1px solid rgba(0,0,0, 0.05);
	float: left;
	height: 55px;
	line-height: 35px;
	list-style: outside none none;
	text-align: center;
	width: 135px;
}
.top-bar-social lii {
	border-left: 1px solid rgba(0,0,0, 0.05);
	float: left;
	height: 55px;
	line-height: 35px;
	list-style: outside none none;
	text-align: center;
	width: 200px;
}
.top-bar-social li:last-child {
  border-right: 1px solid rgba(0,0,0, 0.05);
}
.top-bar-social i {
  color: rgba(170, 170, 170, 0.20);
  font-size: 18px; 
}

/* Icon blocks */
i.icon-circle {
  border-radius: 100px;
  display: block;
  height: 100px;
  line-height: 100px;
  margin-bottom: 0.625rem;
  text-align: center;
  width: 100px;
}
i.icon-circle-small {
  border-radius: 60px;
  display: block;
  font-size: 1.1rem;
  height: 60px;
  line-height: 60px;
  margin-bottom: 0.625rem;
  text-align: center;
  width: 60px;
}


/* Skill Bars */
.skillbar {
background: #f4f4f4 none repeat scroll 0 0;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.04) inset;
  display: block;
  height: 36px;
  margin-bottom: 1em;
  position: relative;
  width: 100%;
-webkit-transition:0.4s linear;
-moz-transition:0.4s linear;
-ms-transition:0.4s linear;
-o-transition:0.4s linear;
transition:0.4s linear;
-webkit-transition-property:width, background-green;
-moz-transition-property:width, background-green;
-ms-transition-property:width, background-green;
-o-transition-property:width, background-green;
transition-property:width, background-green;
}

.skillbar i {
margin-right: 10px
}

.skillbar-title {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: #fff;
  font-size: 0.85rem;
  left: 15px;
  line-height: 36px;
  position: absolute;
  top: 0;
}

.skillbar-bar {
  border-radius: 3px;
  height: 36px;
  width: 0;
}


/* Pricing Tables */
.pricing-table {
	background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
	border-radius: 0 0 4px 4px;
}
h3.pricing-title {
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
	border-radius: 4px 4px 0 0;
	font-size: 1.1em;
  margin: 0;
	padding: 1.25rem 0;
}
.pricing-price {
	font-size: 1.7rem;
  font-weight: 300;
  padding: 0.625rem;
}
.pricing-table li {
	list-style: outside none none;
	padding: 1.25rem;
}
.pricing-table li span {
	font-size: 0.85rem;
}
.pricing-table i {
	margin-right: 0.3125rem;
}

/* Carousel */
.owl-theme .owl-controls {
  margin-top: 0;
}
.owl-theme .owl-controls .owl-page span {
  background: #7697a2 none repeat scroll 0 0;
  border-radius: 0;
  height: 1px;
  margin: 0 1px;
  width: 30px;
}
.owl-theme .owl-controls .owl-buttons div {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #7697a2!important;
  border: 1px solid #7697a2;
  border-radius: 0;
  font-family: mfg;
  height: 40px;
  line-height: 40px;
  margin-top: -20px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;    
  width: 40px;
}
.carousel-bottom-arrows.owl-theme .owl-controls .owl-buttons div {
  bottom: 0;
  top: auto;
}
.owl-theme .owl-controls .owl-buttons .owl-prev {
  left: 0;    
}
.owl-theme .owl-controls .owl-buttons .owl-next {
  right: 0;
}
.carousel-wide-arrows.owl-theme .owl-controls .owl-buttons .owl-prev {
  left: -60px;
}
.carousel-wide-arrows.owl-theme .owl-controls .owl-buttons .owl-next {
  right: -60px;
}
.carousel-nav-white.owl-theme .owl-controls .owl-page span {
  background: #fff none repeat scroll 0 0;
}
.carousel-nav-white.owl-theme .owl-controls .owl-buttons div {
  color: #fff!important;
  border: 1px solid #fff;
}
.carousel-main .carousel-content {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.owl-pagination {
  margin-top: 0.625rem;
}
.carousel-main .owl-pagination {
  bottom: 0;
  padding: 1.25rem 0;
  position: absolute;
  width: 100%;
}
.carousel-blocks {
  padding: 0 3rem;
}
.carousel-blocks .owl-item {
  padding: 0 0.625rem;
}
.carousel-hide-arrows .owl-buttons, .carousel-hide-pagination .owl-pagination {
display:none;
}
.carousel-3-blocks {
  padding: 0 3rem;
}
.carousel-3-blocks .owl-item {
  padding: 0 0.625rem;
}

/* Social */
.text-social, a:link.text-social {
  display: block;
  font-size: 0.85rem;
  font-weight: 700;
  padding: 10px 7px;
  position: relative;
  text-align: center;
  top: 0;
  transition: all 0.20s linear 0s;
-o-transition: all 0.20s linear 0s;
-ms-transition: all 0.20s linear 0s;
-moz-transition: all 0.20s linear 0s;
-webkit-transition: all 0.20s linear 0s;
}
a:link.text-social:hover {
  top: -3px;
}
.facebook, a:link.facebook {
  background: #3b5998 none repeat scroll 0 0;
  color: #fff!important;
}
.twitter, a:link.twitter {
  background: #55acee none repeat scroll 0 0;
  color: #fff!important;
}
.google, a:link.google {
  background: #dd4b39 none repeat scroll 0 0;
  color: #fff!important;
}
.rss, a:link.rss {
  background: #f77f25 none repeat scroll 0 0;
  color: #fff!important;
}
.linkedin, a:link.linkedin {
  background: #007bb5 none repeat scroll 0 0;
  color: #fff!important;
}
.instagram, a:link.instagram {
  background: #125688 none repeat scroll 0 0;
  color: #fff!important;
}
.youtube, a:link.youtube {
  background: #bb0000 none repeat scroll 0 0;
  color: #fff!important;
}
.vine, a:link.vine {
  background: #00bf8f none repeat scroll 0 0;
  color: #fff!important;
}
.pinterest, a:link.pinterest {
  background: #cb2027 none repeat scroll 0 0;
  color: #fff!important;
}
.flickr, a:link.flickr {
  background: #ff0084 none repeat scroll 0 0;
  color: #fff!important;
}

/* Typed */
.typed-cursor{  
  display:none;
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}

/* Portfolio Tabs */
.tab-nav.line {
  display: table;
  padding-bottom: 1.25rem;
}
.tab-item {
  padding: 0;
}
a.tab-label, a.tab-label:link, a.tab-label:visited,
.background-white a.tab-label, .background-white a.tab-label:link, .background-white a.tab-label:visited, .background-white a.tab-label:hover {
  background: #002633 none repeat scroll 0 0;
  color: rgba(255,255,255, 0.75);
}
a.tab-label, a.tab-label:link, a.tab-label:visited, a.tab-label:hover,
.background-white a.tab-label, .background-white a.tab-label:link, .background-white a.tab-label:visited, .background-white a.tab-label:hover {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #002633;
}
.background-white-hightlight a.tab-label.active-btn, .background-white-hightlight a.tab-label:hover,
.primary-color-white .background-primary-hightlight a.tab-label.active-btn, .primary-color-white .background-primary-hightlight a.tab-label:hover {
  background: #fff none repeat scroll 0 0;
  color: #002633;
}
.background-yellow-hightlight a.tab-label.active-btn, .background-yellow-hightlight a.tab-label:hover,
.primary-color-yellow .background-primary-hightlight a.tab-label.active-btn, .primary-color-yellow .background-primary-hightlight a.tab-label:hover {
  background: #F1D529 none repeat scroll 0 0;
  color: #002633;
}
.background-orange-hightlight a.tab-label.active-btn, .background-orange-hightlight a.tab-label:hover,
.primary-color-orange .background-primary-hightlight a.tab-label.active-btn, .primary-color-orange .background-primary-hightlight a.tab-label:hover {
  background: #E4A600 none repeat scroll 0 0;
  color: #fff;
}
.background-red-hightlight a.tab-label.active-btn, .background-red-hightlight a.tab-label:hover,
.primary-color-red .background-primary-hightlight a.tab-label.active-btn, .primary-color-red .background-primary-hightlight a.tab-label:hover {
  background: #C81010 none repeat scroll 0 0;
  color: #fff;
}
.background-pink-hightlight a.tab-label.active-btn, .background-pink-hightlight a.tab-label:hover,
.primary-color-pink .background-primary-hightlight a.tab-label.active-btn, .primary-color-pink .background-primary-hightlight a.tab-label:hover {
  background: #DE1E5E none repeat scroll 0 0;
  color: #fff;
}
.background-blue-hightlight a.tab-label.active-btn, .background-blue-hightlight a.tab-label:hover,
.primary-color-blue .background-primary-hightlight a.tab-label.active-btn, .primary-color-blue .background-primary-hightlight a.tab-label:hover {
  background: #005881 none repeat scroll 0 0;
  color: #fff;
}
.background-aqua-hightlight a.tab-label.active-btn, .background-aqua-hightlight a.tab-label:hover,
.primary-color-aqua .background-primary-hightlight a.tab-label.active-btn, .primary-color-aqua .background-primary-hightlight a.tab-label:hover {
  background: #00B5A6 none repeat scroll 0 0;
  color: #fff;
}
.background-green-hightlight a.tab-label.active-btn, .background-green-hightlight a.tab-label:hover,
.primary-color-green .background-primary-hightlight a.tab-label.active-btn, .primary-color-green .background-primary-hightlight a.tab-label:hover {
  background: #007932 none repeat scroll 0 0;
  color: #fff;
}
.background-dark-hightlight a.tab-label.active-btn, .background-dark-hightlight a.tab-label:hover,
.primary-color-dark .background-primary-hightlight a.tab-label.active-btn, .primary-color-dark .background-primary-hightlight a.tab-label:hover {
  background: #002633 none repeat scroll 0 0;
  color: #fff;
}

/* Forms */
form.customform input, form.customform textarea, form.customform select {
  background: rgba(0, 0, 0, 0.03) none repeat scroll 0 0;
  border: 1px solid rgba(0, 0, 0, 0.14);
  font-size: 12px;
  padding: 0.625rem;
  width: 100%;
}
form.customform input:hover, form.customform textarea:hover, form.customform select:hover, form.customform input:focus, form.customform textarea:focus, form.customform select:focus {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
p.form-error {
  background-color: #DD442C;
  color: #fff;
  display: none;
  font-size: 0.8rem;
  margin: -10px 0 15px 0;
  padding: 7px;
  text-align: center;
}
p.form-success {
  background-color: #9bdd42;
  color: #fff;
  display: none;
  font-size: 0.85rem;
  padding: 10px;
  text-align: center;
}
.required {
  border-left: 1px solid #dd442c !important;
}


/* -1120px version */
@media screen and (max-width:1120px) {
  .size-1140 .line.content-center-vertical {
      padding: 0 2rem;
  }
}


/* -768px version */
@media screen and (max-width:768px) {
    .top-nav .right {
      float: none;
    }
    .logo {
      max-width: 250px;
      margin: 0 auto;
    }
    .nav-text {
      color: #fff;
      display: block;
      font-size: 1.2rem;
      line-height: 3rem;
      margin-right: 0.625rem;
      max-width: 100%;
      text-align: center;
      vertical-align: middle;
    }
    .nav-text::after {
      color: #002633;
      display: inline-block;
      font-size: 3rem;
      margin-left: 0;
      margin-top: 30px;
    }
    .top-nav li a, .background-white .top-nav li a {
      background: #002633 none repeat scroll 0 0;
      color: #fff;
      font-size: 1.1em;
      padding: 1em;
      text-align: center;
    }
    aside {
        border: none;
        padding: 0;
    }
    aside.aside-left {
        border-right: 0;
        padding-right: 0;
    }
    .owl-buttons, .owl-pagination {
      display: none;
    }
    .carousel-main .owl-item img {
      max-width: 300%;
    	width:auto;
    }
    .margin-m-top {
      margin-top: 1.25rem !important;
      display: block;
    }
    .margin-m-bottom {
        margin-bottom: 1.25rem !important;
        display: block;
    }
    .margin-m-left {
        margin-left: 1.25rem !important;
    }
    .margin-m-right {
        margin-right: 1.25rem !important;
    }
    .margin-m-top-bottom {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    display: block;
    }
    .margin-m-left-right {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    .margin-m-top-0 {
        margin-top: 0 !important;
    display: block;
    }
    .margin-m-top-10 {
        margin-top: 10px !important; 
    display: block;
    }
    .margin-m-top-15 {
        margin-top: 15px !important;
    display: block;
    } 
    .margin-m-top-20 {
        margin-top: 20px !important; 
    display: block;
    } 
    .margin-m-top-30 {
        margin-top: 30px !important;
    display: block;
    } 
    .margin-m-top-40 {
        margin-top: 40px !important;
    display: block;
    }
    .margin-m-top-50 {
        margin-top: 50px !important;
    display: block;
    }
    .margin-m-top-60 {
        margin-top: 60px !important;
    display: block;
    }
    .margin-m-top-70 {
        margin-top: 70px !important; 
    display: block;
    }
    .margin-m-top-80 {
        margin-top: 80px !important;
    display: block;
    }
    .margin-m-bottom-0 {
        margin-bottom: 0 !important; 
    display: block;
    }
    .margin-m-bottom-10 {
        margin-bottom: 10px !important; 
    display: block;
    }
    .margin-m-bottom-15 {
        margin-bottom: 15px !important;
    display: block;
    } 
    .margin-m-bottom-20 {
        margin-bottom: 20px !important;
    display: block;
    } 
    .margin-m-bottom-30 {
        margin-bottom: 30px !important;
    display: block;
    } 
    .margin-m-bottom-40 {
        margin-bottom: 40px !important;
    display: block;
    }
    .margin-m-bottom-50 {
        margin-bottom: 50px !important;
    display: block;
    }
    .margin-m-bottom-60 {
        margin-bottom: 60px !important;
    display: block;
    }
    .margin-m-bottom-70 {
        margin-bottom: 70px !important;
    display: block;
    }
    .margin-m-bottom-80 {
        margin-bottom: 80px !important; 
    display: block;
    }
    .margin-m-top-bottom-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    display: block;
    }
    .margin-m-top-bottom-10 {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    display: block;
    }
    .margin-m-top-bottom-15 {
        margin-top: 15px !important;
        margin-bottom: 15px !important; 
    display: block;
    } 
    .margin-m-top-bottom-20 {
        margin-top: 20px !important;
        margin-bottom: 20px !important; 
    display: block;
    } 
    .margin-m-top-bottom-30 {
        margin-top: 30px !important;
        margin-bottom: 30px !important; 
    display: block;
    } 
    .margin-m-top-bottom-40 {
        margin-top: 40px !important;
        margin-bottom: 40px !important; 
    display: block;
    }
    .margin-m-top-bottom-50 {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    display: block;
    }
    .margin-m-top-bottom-60 {
        margin-top: 60px !important;
        margin-bottom: 60px !important; 
    display: block;
    } 
    .margin-m-top-bottom-70 {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    display: block;
    } 
    .margin-m-top-bottom-80 {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    display: block;
    }
    
    .margin-m-left-0 {
        margin-left: 0 !important;
    }
    .margin-m-left-10 {
        margin-left: 10px !important;
    }
    .margin-m-left-15 {
        margin-left: 15px !important;
    } 
    .margin-m-left-20 {
        margin-left: 20px !important;
    } 
    .margin-m-left-30 {
        margin-left: 30px !important;
    } 
    .margin-m-left-40 {
        margin-left: 40px !important;
    }
    .margin-m-left-50 {
        margin-left: 50px !important;
    }
    .margin-m-left-60 {
        margin-left: 60px !important;
    }
    .margin-m-left-70 {
        margin-left: 70px !important;
    }
    .margin-m-left-80 {
        margin-left: 80px !important;
    }
    .margin-m-right-0 {
        margin-right: 0 !important;
    }
    .margin-m-right-10 {
        margin-right: 10px !important;
    }
    .margin-m-right-15 {
        margin-right: 15px !important;
    } 
    .margin-m-right-20 {
        margin-right: 20px !important;
    } 
    .margin-m-right-30 {
        margin-right: 30px !important;
    } 
    .margin-m-right-40 {
        margin-right: 40px !important;
    }
    .margin-m-right-50 {
        margin-right: 50px !important;
    }
    .margin-m-right-60 {
        margin-right: 60px !important;
    }
    .margin-m-right-70 {
        margin-right: 70px !important;
    }
    .margin-m-right-80 {
        margin-right: 80px !important;
    }
    .margin-m-left-right-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .margin-m-left-right-10 {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .margin-m-left-right-15 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    } 
    .margin-m-left-right-20 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    } 
    .margin-m-left-right-30 {
        margin-left: 30px !important;
        margin-right: 30px !important;
    } 
    .margin-m-left-right-40 {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    .margin-m-left-right-50 {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }
    .margin-m-left-right-60 {
        margin-left: 60px !important;
        margin-right: 60px !important;
    } 
    .margin-m-left-right-70 {
        margin-left: 70px !important;
        margin-right: 70px !important;
    } 
    .margin-m-left-right-80 {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }  
    .text-m-size-12 {
      font-size: 12px !important;
      line-height: 1.4;
    }
    .text-m-size-16 {
        font-size: 16px !important;
        line-height: 1.4;
    }
    .text-m-size-20 {
        font-size: 20px !important;
        line-height: 1.4;
    }
    .text-m-size-25 {
        font-size: 25px !important;
        line-height: 1.4;
    }
    .text-m-size-30 {
        font-size: 30px !important;
        line-height: 1.4;
    }
    .text-m-size-40 {
        font-size: 40px !important;
        line-height: 1.4;
    }
    .text-m-size-50 {
        font-size: 50px !important;
        line-height: 1.4;
    }
    .text-m-size-60 {
        font-size: 60px !important;
        line-height: 1.4;
    }
    .text-m-size-70 {
        font-size: 70px !important;
        line-height: 1.4;
    } 
    .owl-buttons {
      display: none;
    }
}  

/* -480px version */
@media screen and (max-width:480px) {
    .top-bar .right {
      float: none;
    }
    .top-bar-contact p {
      height: auto;
      line-height: 1.3rem;
      padding: 10px 0;
      text-align: center;
    }
    .top-bar-social.right {
      display: table;
      float: none;
      margin: 0 auto;
      padding: 0;
      text-align: center;
    }
    .margin-s-top {
        margin-top: 1.25rem !important;
        display: block;
    }
    .margin-s-bottom {
        margin-bottom: 1.25rem !important; 
        display: block;
    }
    .margin-s-left {
        margin-left: 1.25rem !important;
    }
    .margin-s-right {
        margin-right: 1.25rem !important;
    }
    .margin-s-top-bottom {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
        display: block;
    }
    .margin-s-left-right {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    .margin-s-top-0 {
        margin-top: 0 !important; 
        display: block;
    }
    .margin-s-top-10 {
        margin-top: 10px !important;
        display: block;
    }
    .margin-s-top-15 {
        margin-top: 15px !important;
        display: block;
    } 
    .margin-s-top-20 {
        margin-top: 20px !important;
        display: block;
    } 
    .margin-s-top-30 {
        margin-top: 30px !important; 
        display: block;
    } 
    .margin-s-top-40 {
        margin-top: 40px !important; 
        display: block;
    }
    .margin-s-top-50 {
        margin-top: 50px !important;
        display: block;
    }
    .margin-s-top-60 {
        margin-top: 60px !important;
        display: block;
    }
    .margin-s-top-70 {
        margin-top: 70px !important;
        display: block;
    }
    .margin-s-top-80 {
        margin-top: 80px !important;
        display: block;
    }
    .margin-s-bottom-0 {
        margin-bottom: 0 !important; 
        display: block;
    }
    .margin-s-bottom-10 {
        margin-bottom: 10px !important;
        display: block;
    }
    .margin-s-bottom-15 {
        margin-bottom: 15px !important; 
        display: block;
    } 
    .margin-s-bottom-20 {
        margin-bottom: 20px !important; 
        display: block;
    } 
    .margin-s-bottom-30 {
        margin-bottom: 30px !important; 
        display: block;
    } 
    .margin-s-bottom-40 {
        margin-bottom: 40px !important;
        display: block;
    }
    .margin-s-bottom-50 {
        margin-bottom: 50px !important;
        display: block;
    }
    .margin-s-bottom-60 {
        margin-bottom: 60px !important; 
        display: block;
    }
    .margin-s-bottom-70 {
        margin-bottom: 70px !important; 
        display: block;
    }
    .margin-s-bottom-80 {
        margin-bottom: 80px !important;
        display: block;
    }
    .margin-s-top-bottom-0 {
        margin-top: 0 !important; 
        margin-bottom: 0 !important;
        display: block; 
    }
    .margin-s-top-bottom-10 {
        margin-top: 10px !important; 
        margin-bottom: 10px !important;
        display: block; 
    }
    .margin-s-top-bottom-15 {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        display: block;
    } 
    .margin-s-top-bottom-20 {
        margin-top: 20px !important;  
        margin-bottom: 20px !important; 
        display: block;
    } 
    .margin-s-top-bottom-30 {
        margin-top: 30px !important; 
        margin-bottom: 30px !important; 
        display: block;
    } 
    .margin-s-top-bottom-40 {
        margin-top: 40px !important; 
        margin-bottom: 40px !important;
        display: block; 
    }
    .margin-s-top-bottom-50 {
        margin-top: 50px !important; 
        margin-bottom: 50px !important; 
        display: block;
    }
    .margin-s-top-bottom-60 {
        margin-top: 60px !important;
        margin-bottom: 60px !important; 
        display: block;
    } 
    .margin-s-top-bottom-70 {
        margin-top: 70px !important; 
        margin-bottom: 70px !important; 
        display: block;
    } 
    .margin-s-top-bottom-80 {
        margin-top: 80px !important;
        margin-bottom: 80px !important; 
        display: block;
    }
    
    .margin-s-left-0 {
        margin-left: 0 !important;
    }
    .margin-s-left-10 {
        margin-left: 10px !important;
    }
    .margin-s-left-15 {
        margin-left: 15px !important;
    } 
    .margin-s-left-20 {
        margin-left: 20px !important;
    } 
    .margin-s-left-30 {
        margin-left: 30px !important;
    } 
    .margin-s-left-40 {
        margin-left: 40px !important;
    }
    .margin-s-left-50 {
        margin-left: 50px !important;
    }
    .margin-s-left-60 {
        margin-left: 60px !important;
    }
    .margin-s-left-70 {
        margin-left: 70px !important;
    }
    .margin-s--80 {
        margin-left: 80px !important;
    }
    .margin-s-right-0 {
        margin-right: 0 !important;
    }
    .margin-s-right-10 {
        margin-right: 10px !important;
    }
    .margin-s-right-15 {
        margin-right: 15px !important;
    } 
    .margin-s-right-20 {
        margin-right: 20px !important;
    } 
    .margin-s-right-30 {
        margin-right: 30px !important;
    } 
    .margin-s-right-40 {
        margin-right: 40px !important;
    }
    .margin-s-right-50 {
        margin-right: 50px !important;
    }
    .margin-s-right-60 {
        margin-right: 60px !important;
    }
    .margin-s-right-70 {
        margin-right: 70px !important;
    }
    .margin-s-right-80 {
        margin-right: 80px !important;
    }
    .margin-s-left-right-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .margin-s-left-right-10 {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .margin-s-left-right-15 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    } 
    .margin-s-left-right-20 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    } 
    .margin-s-left-right-30 {
        margin-left: 30px !important;
        margin-right: 30px !important;
    } 
    .margin-s-left-right-40 {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    .margin-s-left-right-50 {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }
    .margin-s-left-right-60 {
        margin-left: 60px !important;
        margin-right: 60px !important;
    } 
    .margin-s-left-right-70 {
        margin-left: 70px !important;
        margin-right: 70px !important;
    } 
    .margin-s-left-right-80 {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }
    .text-s-size-12 {
      font-size: 12px !important;
      line-height: 1.4;
    }
    .text-s-size-16 {
        font-size: 16px !important;
        line-height: 1.4;
    }
    .text-s-size-20 {
        font-size: 20px !important;
        line-height: 1.4;
    }
    .text-s-size-25 {
        font-size: 25px !important;
        line-height: 1.4;
    }
    .text-s-size-30 {
        font-size: 30px !important;
        line-height: 1.4;
    }
    .text-s-size-40 {
        font-size: 40px !important;
        line-height: 1.4;
    }
    .text-s-size-50 {
        font-size: 50px !important;
        line-height: 1.4;
    }
    .text-s-size-60 {
        font-size: 60px !important;
        line-height: 1.4;
    }
    .text-s-size-70 {
        font-size: 70px !important;
        line-height: 1.4;
    }
} 

/* Typography */
.text-uppercase {
  text-transform: uppercase;
  line-height: 1;
}
.text-line-height-1 {
  line-height: 1;
}

/* Containers */
.text-padding {
  display: inline-block !important;
  padding: 15px 20px;
}
.text-padding-small {
  display: inline-block !important;
  padding: 7px 10px;
}


/* Custom Template Styles */
@media screen and (max-width:768px) {
  .fixed {
    position: relative;
    z-index: 10;
  }
}
