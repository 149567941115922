@charset "utf-8";
.a-text {text-color:red#EB0B0F
}
.modal-header.close {
      background-color: #5cb85c;
      color:white !important;
      text-align: center;
      font-size: 30px;
  }
  .modal-footer {
      background-color: #f9f9f9;
  }
.modalDialog {
		position: fixed;
		font-family: Arial, Helvetica, sans-serif;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0.8);
		z-index: 99999;
		opacity:0;
		-webkit-transition: opacity 400ms ease-in;
		-moz-transition: opacity 400ms ease-in;
		transition: opacity 400ms ease-in;
		pointer-events: none;
	}

	.modalDialog:target {
		opacity:1;
		pointer-events: auto;
	}

	.modalDialog > div {
		width: 400px;
		position: relative;
		margin: 10% auto;
		padding: 5px 20px 13px 20px;
		border-radius: 10px;
		background: #fff;
		background: -moz-linear-gradient(#fff, #b7da9b);
		background: -webkit-linear-gradient(#fff, #b7da9b);
		background: -o-linear-gradient(#fff, #b7da9b);
	}

	.close {
		background: #606061;
		color: #FFFFFF;
		line-height: 25px;
		position: absolute;
		right: -12px;
		text-align: center;
		top: -10px;
		width: 24px;
		text-decoration: none;
		font-weight: bold;
		-webkit-border-radius: 12px;
		-moz-border-radius: 12px;
		border-radius: 12px;
		-moz-box-shadow: 1px 1px 3px #000;
		-webkit-box-shadow: 1px 1px 3px #000;
		box-shadow: 1px 1px 3px #000;
	}

	.close:hover { background: #007932; }

#modal .contents
{
   /* style the modal's contents, in this case we're just adding padding */
   padding:24px;
}
#blind
{
   /* position element so the z-index can be set */
   position:absolute;
   /* set z-index so the blind will cover all page content */
   z-index:9999;
   /* set the top, left, width and height so the blind covers the browser window */
   top:0;
   left:0;
   width:100%;
   height:100%;
   /* set the background to a suitably dark tone */
   background-color:#000000;
}
#modal .window {
   /* position the element so that the z-index can be applied */
   position:absolute;
   /* Set the z-index to a number higher than the blind's z-index */
   z-index:10000;
   /* set the width and height of the window */
   width:400px;
   height:248px;
   /* give the window negative margins that match the width/2 and height/2 so it is centered */
   margin-left:-200px;
   margin-top:-124px;
   /* position the top left corner off stage so it can't be seen (instead of display:none;) */
   left:-1000px;
   top:-1000px;
   /* color the background so it shows up */
   background-color:#ffffff;
   /* throw on a CSS3 box shadow, because it's cool, and we can */
   
   -webkit-box-shadow:4px 4px 80px #000;
   -moz-box-shadow:4px 4px 80px #000;
    box-shadow:4px 4px 80px #000;
}





/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5px auto; /* 15% from the top and centered */
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
	/* Position it in the top right corner outside of the modal */
	position: absolute;
	right: 33px;
	top: 2px;
	color: #000;
	font-size: 35px;
	font-weight: bold;
}

/* Close button on hover */
.close:hover,
.close:focus {
    color: red;
    cursor: pointer;
}

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
    from {-webkit-transform: scale(0)} 
    to {-webkit-transform: scale(1)}
}

@keyframes animatezoom {
    from {transform: scale(0)} 
    to {transform: scale(1)}
}

  
